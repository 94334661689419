<template>
  <div>
    <iframe
      class="cover"
      src="/background"
      allowtransparency="true"
    />
    <iframe
      class="cover"
      src="/foreground"
      allowtransparency="true"
    />
  </div>
</template>

<script>
  export default {
    name: "BroadcastSimulationView",
    props: {
      preloadComplete: {
        type: Boolean,
        required: false,
      },
    },
    created() {
      this.$nextTick(() => {
        document.documentElement.style.background = "transparent";
      });

      // Bind youtube playlists
      this.$store.dispatch("youtube/bindPlaylists");
    },
  };
</script>

<style lang="scss" scoped>
  .cover {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0;
  }
</style>
